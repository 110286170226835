<template>
 <div class="h-full py-24 sm:py-32">
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
   <div class="mx-auto max-w-2xl lg:text-center">
    <!-- <h2 class="text-base font-semibold leading-7 encom_primary_text">
     Le service {{ this.$route?.params.serviceName }} n'est pas activé.
    </h2> -->
    <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
     {{ $t("enableServiceTitle", { service: this.$route?.params.serviceName }) }}
    </p>
    <p class="mt-3 text-base text-gray-600">{{ $t("enableServiceText") }}.</p>
   </div>
   <div class="flex justify-center">
    <div class="mx-auto mt-16 max-w-2xl lg:max-w-4xl">
     <ul :class="`grid gap-6 grid-cols-${people.length}`" role="list">
      <li
       v-for="person in people"
       :key="person.email"
       class="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow"
      >
       <div class="flex w-full items-center justify-between space-x-6 p-6">
        <div class="flex-1 truncate">
         <div class="flex items-center space-x-3">
          <h3 class="truncate text-sm font-medium text-gray-900">{{ person.name }}</h3>
         </div>
        </div>
        <!-- <img
         class="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300"
         :src="getAvatarUrl(person.id)"
         alt=""
        /> -->
        <span class="inline-block h-10 w-10 overflow-hidden rounded-full bg-gray-100">
         <svg class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
          <path
           d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
          />
         </svg>
        </span>
       </div>

       <div>
        <div class="-mt-px flex divide-x divide-gray-200">
         <div class="flex w-0 flex-1">
          <a
           :href="`mailto:${person.emailShown}`"
           class="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
          >
           <MailIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />

           Email
          </a>
         </div>
         <div v-if="person.phone || person.phoneDirect" class="-ml-px flex w-0 flex-1">
          <a
           :href="`tel:${person.phone ? person.phone : person.phoneDirect}`"
           class="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
          >
           <PhoneIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
           Call
          </a>
         </div>
        </div>
       </div>
      </li>
     </ul>
    </div>
   </div>
  </div>
 </div>
</template>

<script>
import axios from "axios";
import { MailIcon, PhoneIcon } from "@heroicons/vue/solid";
const account = localStorage.getItem("account");

export default {
 name: "EnableService",
 props: [],
 components: {
  MailIcon,
  PhoneIcon,
 },
 data() {
  return { account, people: [] };
 },
 methods: {
  async getAdminSale() {
   try {
    const res = await axios.get(
     `${this.$cookie.getCookie("API")}/api/v1/admins/sales?customerAccount=${this.account}`,
     {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
     }
    );
    this.people = res.data;
   } catch (error) {
    console.error(error);
   } finally {
    this.$emit("setLoading", false);
   }
  },
  /*  async checkImageStatus(personId) {
   try {
    const response = await axios.get(`${this.$cookie.getCookie("API")}/avatar/user/${personId}`);
    if (response.status !== 200) {
     console.error("Failed to load image:", response.status);
     return null;
    }
    console.log(res.data);
    return response.data;
   } catch (error) {
    console.error("Error loading image:", error);
    return null;
   }
  },
  async getAvatarUrl(personId) {
   const imageUrl = await this.checkImageStatus(personId);
   if (imageUrl) {
    return imageUrl;
   }
   return "/default-avatar.jpg";
  }, */
  signOut() {
   localStorage.removeItem("token");
   localStorage.removeItem("adminToken");
   localStorage.removeItem("avatar");
   localStorage.removeItem("account");
   this.$store.dispatch("user", {});
   this.$store.dispatch("adminToken", "");
   this.$store.dispatch("token", "");
   this.$store.dispatch("userAvatar", "");
   this.$store.dispatch("account", "");
   this.$router.push("/login");
   location.reload();
  },
 },
 mounted() {
  this.getAdminSale();
 },
};
</script>

<style></style>
